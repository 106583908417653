<template>
  <div>
    <v-simple-table
      fixed-header
      height="calc(100vh - 145px)"
      class="table-padding-2"
    >
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <th role="columnheader" style="min-width: 100px">
              <InputFilter
                :label="$t('labels.goods_name')"
                :placeholder="$t('labels.goods_name')"
                name="name"
                sort-name="name"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 100px">
              <InputFilter
                :label="$t('labels.goods_description')"
                :placeholder="$t('labels.goods_description')"
                name="description"
                sort-name="description"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 100px">
              <InputFilter
                :label="$t('labels.size')"
                :placeholder="$t('labels.size')"
                name="size"
                sort-name="size"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 100px">
              <InputFilter
                :label="$t('labels.unit')"
                :placeholder="$t('labels.unit')"
                name="unit"
                sort-name="unit"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 100px">
              <SelectFilter
                :options="imageOptions"
                :label="$t('labels.image')"
                :placeholder="$t('labels.image')"
                name="image"
                sort-name="url_images"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 100px">
              <InputFilter
                :label="$t('labels.sku')"
                :placeholder="$t('labels.sku')"
                name="sku"
                sort-name="sku"
                :default-value="filters.sku"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 100px">
              <InputFilter
                :label="$t('labels.barcode')"
                :placeholder="$t('labels.barcode')"
                name="customer_goods_barcode"
                :default-value="filters.customer_goods_barcode"
                sort-name="customer_goods_barcode"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 100px">
              <InputFilterFromTo
                :label="$t('labels.receiving')"
                :placeholder="$t('labels.receiving')"
                name="receiving"
                sort-name="receiving"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 100px">
              <InputFilterFromTo
                :label="$t('labels.wait_pickup')"
                :placeholder="$t('labels.wait_pickup')"
                name="wait_pickup"
                sort-name="wait_pickup"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 100px">
              <InputFilterFromTo
                :label="$t('labels.available')"
                :placeholder="$t('labels.available')"
                name="available"
                sort-name="available"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" :key="item.id" class="text-center">
            <td>{{ item.name }}</td>
            <td>{{ item.description }}</td>
            <td>{{ item.size }}</td>
            <td>{{ item.unit }}</td>
            <td>
              <ImageViewer v-if="item.url_images" :url="item.url_images" />
            </td>
            <td>{{ item.sku }}</td>
            <td>{{ item.customer_goods_barcode }}</td>
            <td>{{ formatNumber(item.receiving) }}</td>
            <td>{{ formatNumber(item.wait_pickup) }}</td>
            <td>{{ formatNumber(item.available) }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-row class="pt-3">
      <v-col cols="12" sm="4">
        <div class="d-flex table-footer-count">
          <div class="mr-2">
            {{ $t("labels.sku") }}: <b>{{ formatNumber(sum.total_sku) }}</b>
          </div>

          <div class="mr-2">
            {{ $t("labels.available") }}:
            <b>{{ formatNumber(sum.total_available) }}</b>
          </div>
        </div>
        <div class="d-flex table-footer-count mt-1">
          <div class="mr-2">
            {{ $t("labels.receiving") }}:
            <b>{{ formatNumber(sum.total_receiving) }}</b>
          </div>
          <div class="mr-2">
            {{ $t("labels.wait_pickup") }}:
            <b>{{ formatNumber(sum.total_waitpickup) }}</b>
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="4">
        <v-pagination
          v-model="page"
          :length="totalPage"
          :total-visible="5"
        ></v-pagination>
      </v-col>
      <v-col cols="12" sm="4">
        <div class="d-flex justify-end w-100">
          <v-btn
            color="success"
            @click="exportExcel"
            class="mr-2"
            style="height: 40px"
            ><v-icon>mdi-download</v-icon> Excel</v-btn
          >
          <v-text-field
            dense
            outlined
            hide-details
            v-model.number="page"
            :label="$t('labels.page')"
            :placeholder="$t('labels.page')"
            class="mr-2"
            style="max-width: 120px"
          ></v-text-field>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  debounce,
  downloadExcelFile,
  formatNumber,
  getUrlParameter,
} from "@/libs/helpers";
import { httpClient } from "@/libs/http";
import { IMAGE_OPTIONS, TRANSPORT_OPTIONS, YES_NO_OPTIONS } from "@/libs/const";

export default {
  name: "Goods",
  components: {
    SelectFilter: () => import("@/components/table/SelectFilter"),
    InputFilter: () => import("@/components/table/InputFilter"),
    InputFilterFromTo: () => import("@/components/table/InputFilterFromTo"),
    ImageViewer: () => import("@/components/goods/ImageViewer"),
  },
  data: () => ({
    page: 1,
    totalPage: 1,
    total: 0,
    items: [],
    filters: {},
    imageOptions: [...IMAGE_OPTIONS],
    transportOptions: [...TRANSPORT_OPTIONS],
    yesNoOptions: [...YES_NO_OPTIONS],
    isLoading: false,
    propertyDialog: false,
    costDialog: false,
    uidDialog: false,
    uidSku: null,
    updatingItem: {},
    sum: {},
  }),
  watch: {
    page() {
      this.getList();
    },
    filters: {
      handler() {
        this.page = 1;
        this.getList();
      },
      deep: true,
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    downloadExcelFile,
    formatNumber,
    getUrlParameter,

    onFilterChange(filter) {
      if (filter.name === "sku") {
        let valueSpl = (filter.value && filter.value.split("@")) || [""];
        this.filters = { ...this.filters, [filter.name]: valueSpl[0] };
      } else {
        this.filters = { ...this.filters, [filter.name]: filter.value };
      }
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },
    async exportExcel() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.waiting_file_download"));
        return false;
      }
      this.isLoading = true;
      const filename = "hang-hoa-dc.xlsx";

      try {
        await this.downloadExcelFile(
          "/dc/v1/goods-export",
          { ...this.filters },
          filename
        );
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },
    getList: debounce(function () {
      httpClient
        .post("/dc/v1/goods-list", {
          ...this.filters,
          page: this.page,
        })
        .then(({ data }) => {
          this.totalPage = data.totalPage;
          this.total = data.total;
          this.sum = { ...data.sum };
          this.items = [...data.rows];
        });
    }, 500),
  },
};
</script>
